<template>
  <v-container fluid class="down-top-padding">
    <BaseBreadcrumb :title="page.title" :module_name="page.name"/>
    <v-row>
      <v-col>
        <v-card class="pa-5" :loading="loading" :disabled="loading">
          <v-data-table
              :headers="headers"
              :items="records"
              :loading="loading"
              class="elevation-1"
              item-key="id"
              selectable-key="id"
              :show-select="false"
              :options.sync="options"
              :footer-props="footer_props"
              :custom-sort="customSort"
              :search="search">
            <template v-slot:top>
              <v-text-field
                  v-model="search"
                  @click:clear="clearSearch"
                  :label="$store.getters.translate('search')"
                  clearable
                  outlined
                  dense
                  append-icon="mdi-magnify"/>
            </template>
            <template v-slot:body="props">
              <tbody>
                <tr v-for="(item, index) in props.items" :key="index">
                  <td><a @click="editExam(item)">{{ item.id }}</a></td>
                  <td><a @click="editExam(item)">{{ item.name }}</a></td>
                  <td>{{ item.examination_date }}</td>
                  <td>{{ item.location_name }}</td>
                  <td><v-checkbox v-model="item.joint_planning" :value="item.joint_planning" @change="fieldDataChanged(item, 'joint_planning')"/></td>
                  <td><v-checkbox v-model="item.location_requested" :value="item.location_requested" @change="fieldDataChanged(item, 'location_requested')"/></td>
                  <td><v-checkbox v-model="item.location_agreed" :value="item.location_agreed" @change="fieldDataChanged(item, 'location_agreed')"/></td>
                  <td><v-checkbox v-model="item.practical_planned" :value="item.practical_planned" @change="fieldDataChanged(item, 'practical_planned')"/></td>
                  <td><v-checkbox v-model="item.examiner_requested" :value="item.examiner_requested" @change="fieldDataChanged(item, 'examiner_requested')"/></td>
                  <td><v-checkbox v-model="item.examiner_agrees" :value="item.examiner_agrees" @change="fieldDataChanged(item, 'examiner_agrees')"/></td>
                  <td><v-checkbox v-model="item.passed_on_to_customer" :value="item.passed_on_to_customer" @change="fieldDataChanged(item, 'passed_on_to_customer')"/></td>
                  <td><v-checkbox v-model="item.stipel" :value="item.stipel" @change="fieldDataChanged(item, 'stipel')"/></td>
                  <td><v-checkbox v-model="item.running_projects" :value="item.running_projects" @change="fieldDataChanged(item, 'running_projects')"/></td>
                  <td><v-checkbox v-model="item.plans_completed" :value="item.plans_completed" @change="fieldDataChanged(item, 'plans_completed')"/></td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import BaseBreadcrumb from "../../components/commonComponents/BaseBreadcrumb";
import helpFunctions from "../../plugins/helpFunctions";
import moment from "moment";

export default {
  components: {
    BaseBreadcrumb,
  },
  data() {
    return {
      page: {
        title: this.$store.getters.translate("exam_statuses"),
        name: "examstatuses",
        model: "examstatus",
      },
      loading: false,
      headers: [],
      records: [],
      search: "",
      options: {},
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200],
        rowsPerPageText: "Rows per page:",
      },
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
    }
    this.headers = helpFunctions.examstatuses_headers;
    this.load();
  },
  methods: {
    load() {
      this.loading = true;
      this.$http
          .get(this.$store.getters.appUrl + "v2/get-exam-statuses")
          .then((response) => {
            this.records = response.data;
            this.loading = false;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
    },
    fieldDataChanged(item, status) {
      let payload = {
        exam_id: item.id,
        status: status
      }
      if (item[status]) {
        payload.action = 'set';
      }
      else {
        payload.action = 'remove';
      }
      this.loading = true;
      this.$http
          .post(this.$store.getters.appUrl + "v2/update-exam-status", payload)
          .then(() => {
            this.loading = false;
            this.$toasted.success(this.$store.getters.translate("success"));
          })
          .catch((error) => {
            this.loading = false;
            this.$toasted.error(error);
          });
    },
    clearSearch() {
      this.search = "";
    },
    customSort(items, index, isDesc) {
      let field = index[0];
      let desc = isDesc[0];
      let result = this.$lodash.sortBy(items, field);
      if (field === "examination_date") {
        result = result.sort((a, b) => {
          a = moment(a["examination_date"], "DD-MM-YYYY").toDate();
          b = moment(b["examination_date"], "DD-MM-YYYY").toDate();
          return a - b;
        });
      }
      if (desc) {
        return result.reverse();
      }
      return result;
    },
    editExam(item) {
      this.$router.push("/exams/" + item.id);
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
      },
      deep: true,
    },
  },
};
</script>
